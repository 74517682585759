import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faFolder, faBook } from '@fortawesome/free-solid-svg-icons';
import Modal from './Modal';

const Carousel = ({ slides, isPageBackground, type }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const moveSlide = (direction) => {
    setCurrentIndex((prevIndex) => {
      if (direction === 'left') {
        return (prevIndex - 1 + slides.length) % slides.length;
      } else {
        return (prevIndex + 1) % slides.length;
      }
    });
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const getSlideStyle = (index) => {
    const totalSlides = slides.length;
    const position = (index - currentIndex + totalSlides) % totalSlides;
    
    let transform = '';
    let opacity = 1;
    let zIndex = 1;

    switch (position) {
      case 0: // center
        transform = 'translateX(-50%) scale(1)';
        zIndex = 5;
        break;
      case 1: // right
        transform = 'translateX(30%) scale(0.8) rotateY(-30deg)';
        opacity = 0.4;
        zIndex = 4;
        break;
      case totalSlides - 1: // left
        transform = 'translateX(-130%) scale(0.8) rotateY(30deg)';
        opacity = 0.4;
        zIndex = 4;
        break;
      case 2: // far right
        transform = 'translateX(150%) scale(0.6)';
        opacity = 0;
        zIndex = 3;
        break;
      case totalSlides - 2: // far left
        transform = 'translateX(-250%) scale(0.6)';
        opacity = 0;
        zIndex = 3;
        break;
      default:
        opacity = 0;
        zIndex = 1;
    }

    return {
      transform,
      opacity,
      zIndex,
      transition: 'all 0.3s ease'
    };
  };

  const getIcon = () => {
    switch (type) {
      case 'book':
        return faBook;
      case 'project':
      default:
        return faFolder;
    }
  };

  const activeSlide = slides[currentIndex];

  return (
    <div className="flex flex-col items-center justify-center bg-stone-900 md:p-4 md:flex-row">
      {/* Active Slide Card - Hidden on mobile */}
      <div className="hidden md:flex w-80 h-96 bg-stone-800 p-6 rounded-lg shadow-lg mr-12 mb-8 md:mb-0 flex-col">
        <div className="flex justify-between items-center mb-4">
          <FontAwesomeIcon icon={getIcon()} className="text-5xl text-yellow-600 mr-4" />
          <p className="text-yellow-600 text-sm">{activeSlide.subtitle}</p>
        </div>
        <h3 className="text-white text-xl font-bold mb-4">{activeSlide.title}</h3>
        <p className="text-white text-sm overflow-hidden flex-grow">
          {activeSlide.description}
        </p>
        <button 
        onClick={openModal}
        className="mt-4 px-4 py-2 bg-yellow-600 text-white rounded self-start">
          Læs mere
        </button>
      </div>

      {/* Carousel */}
      <div className="relative w-full max-w-3xl h-96 overflow-hidden">
        <div className="absolute inset-0 flex items-center justify-center [perspective:1000px]">
          {slides.map((slide, index) => (
            <div
              key={slide.id}
              className="slide-content absolute left-1/2"
              style={getSlideStyle(index)}
            >
              <div 
                className="w-full h-full bg-cover bg-center rounded-2xl shadow-lg"
                style={{ backgroundImage: `url(${slide.image})` }}
              >
                <div className="slide-content-inner absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4 rounded-b-2xl">
                  <h2 className="slide-title text-lg font-semibold">{slide.title}</h2>
                  <p className="text-sm">{slide.subtitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={() => moveSlide('left')}
          className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 z-10"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          onClick={() => moveSlide('right')}
          className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 z-10"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      {/* "Læs mere" button - Visible only on mobile */}
      <button
      onClick={openModal} 
      className="md:hidden mt-4 px-4 py-2 bg-yellow-600 text-white rounded">
        Læs mere
      </button>

       {/* Modal */}
       <Modal isOpen={isModalOpen} onClose={closeModal} project={activeSlide} />
    </div>
  );
};

export default Carousel;