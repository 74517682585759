import React from 'react';

const SkillBar = ({ skill, level }) => {
  const maxLevel = 5;
  const dots = Array(maxLevel).fill(0);
  
  return (
    <div className="mb-4">
      <div className="flex justify-between mb-1">
        <span className="text-sm font-medium">{skill}</span>
      </div>
      <div className="flex gap-1">
        {dots.map((_, index) => (
          <div
            key={index}
            className={`h-2 w-full rounded ${
              index < level ? 'bg-rose-500' : 'bg-gray-300'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

const Introduction = () => {
  const skills = [
    { name: "Angular", level: 3 },
    { name: "JS/TS", level: 4 },
    { name: "Java", level: 2 },
    { name: "C#", level: 4 },
    { name: "HTML/CSS", level: 3 }
  ];

  const interests = [
    {
      title: "Backend / frontend udvikling",
      description: "Jeg interesserer mig for at skabe effektive frontend- og backend-løsninger, der kombinerer brugervenlighed med teknisk præcision og skalerbarhed. Mit fokus er på at levere struktureret, vedligeholdelsesvenlig kode, der kan udvikles og tilpasses over tid."
    },
    {
      title: "Backend arkitektur",
      description: "Jeg finder server-side teknologier og arkitektur fascinerende, især arbejdet med at skabe robuste, skalerbare strukturer. Her er mit fokus på komponentdesign, integration og at sikre effektiv kommunikation mellem services, alt imens jeg tager højde for belastning og fremtidig udvidelse."
    },
    {
      title: "Database Design og Udvikling",
      description: "Jeg nyder at arbejde med struktureret datastyring og design af databaser, hvor jeg fokuserer på optimal datahåndtering og skalerbare dataløsninger. Jeg er nysgerrig på både relationelle databaser og NoSQL-løsninger, og hvordan forskellige designprincipper kan skabe værdi i forskellige projekter."
    }
  ];

  return (
    <section id="aboutme" className="flex-1 bg-gray-200">
      <div className="container mx-auto py-24 px-4">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* About Me Section */}
          <div className="lg:col-span-2 bg-neutral-100 rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-semibold mb-4 text-center">Lidt om mig</h2>
            <hr className="border-t-2 border-rose-500 w-28 mx-auto mb-8" />
            <div className="space-y-4">
            <p className="">
              Jeg er en <b className='font-bold'>engageret</b> IT studerende med en stor <b className='font-bold'>passion</b> for softwareudvikling og 
              teknologisk innovation. 
            </p>
            <p>
              I min <b className='font-bold'>fritid</b> nørder jeg madlavning, 
              små kodeprojekter og spiller 
              bræt- og computerspil - hvis jeg 
              altså ikke befinder mig i et koldt 
              bad eller i den nærmeste sauna.
            </p>
            <p>
              Endvidere har jeg en naturlig 
              <b className='font-bold'> nysgerrighed</b> for alt jeg laver, og 
              brænder for kontinuerlig <b className='font-bold'>læring </b>
              og <b className='font-bold'>udvikling</b>. Både på et 
              personligt og proffessionelt plan.
            </p>
            <p>
              Som en del af et team bestræber jeg mig 
              altid efter at levere kvalitet gennem 
              min detaljeorienteret og strukturerede tilgang. 
              Desuden bidrager jeg med god energi og massere af smil.
            </p>
            </div>
          </div>

          {/* Profile Image */}
          <div className="flex justify-center items-center">
            <img
              src="./images/me.jpg"
              alt="Frederik Emil Albin Schmidt"
              className="w-64 h-64 rounded-full object-cover shadow-lg"
            />
          </div>
        </div>

         {/* Second Row: Software Interests */}
         <div className="mt-8">
          <div className="bg-neutral-100 rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-semibold mb-4 text-center">Software Interesser - Indtil videre</h2>
            <hr className="border-t-2 border-rose-500 w-40 mx-auto mb-8" />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {interests.map((interest, index) => (
                <div 
                  key={index} 
                  className="p-4 "
                >
                  <h3 className="text-lg font-semibold mb-2 text-rose-500">{interest.title}</h3>
                  <p className="text-gray-600">{interest.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CV Sections */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8">
          {/* Current Job */}
          <div className="bg-neutral-100 rounded-lg shadow-lg p-8">
            <h3 className="text-xl font-bold mb-4">Nuværende Stilling</h3>
            <hr className="border-t-2 border-rose-500 w-20 mb-4" />
            <div className="space-y-2">
              <div>
                <h4 className="font-semibold">Full-stack software Developer</h4>
                <p className="text-gray-600">Rina Digital Solutions</p>
                <p className="text-sm">Jun. 2024 - Nu</p>
                <p className="mt-2">
                  Her arbejder jeg som full-stack udvikler i C# og Angular. Her indgår jeg i et team, hvor vi arbejder med at udvikle og vedligeholde en webapplikation. 
                </p>
              </div>
            </div>
          </div>

          {/* Education */}
          <div className="bg-neutral-100 rounded-lg shadow-lg p-8">
            <h3 className="text-xl font-bold mb-4">Uddannelse</h3>
            <hr className="border-t-2 border-rose-500 w-20 mb-4" />
            <div className="space-y-4">
              <div>
                <h4 className="font-semibold">Softwareudvikler</h4>
                <p className="text-gray-600">Universty College of Nothern Denmark</p>
                <p className="text-sm">Jan. 2024 - Jun. 2025</p>
              </div>
              <div>
                <h4 className="font-semibold">Datamatiker</h4>
                <p className="text-gray-600">Universty College of Nothern Denmark</p>
                <p className="text-sm">Jun. 2021 - Dec. 2023</p>
              </div>
              <div>
                <h4 className="font-semibold">STX</h4>
                <p className="text-gray-600">Dronninglund Gymnasium</p>
                <p className="text-sm">2014 - 2017</p>
              </div>
            </div>
          </div>

          {/* Skills */}
          <div className="bg-neutral-100 rounded-lg shadow-lg p-8">
            <h3 className="text-xl font-bold mb-4">Kompetencer</h3>
            <hr className="border-t-2 border-rose-500 w-20 mb-4" />
            <div>
              {skills.map((skill) => (
                <SkillBar 
                  key={skill.name} 
                  skill={skill.name} 
                  level={skill.level} 
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Introduction;