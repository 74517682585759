import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFolder } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ isOpen, onClose, project }) => {
  useEffect(() => {
    if (isOpen) {
      // Disable scroll on the body when modal is open
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scroll when modal is closed
      document.body.style.overflow = 'unset';
    }

    // Cleanup function to re-enable scroll when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-stone-800 rounded-lg shadow-xl w-full max-w-4xl h-[80vh] flex flex-col overflow-hidden">
        {/* Fixed header */}
        <div className="p-6 bg-stone-800 border-b border-stone-700">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faFolder} className="text-4xl text-yellow-600 mr-4" />
              <div>
                <h2 className="text-2xl font-bold text-white">{project.title}</h2>
                <p className="text-yellow-600 mt-1">{project.subtitle}</p>
              </div>
            </div>
            <button 
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <FontAwesomeIcon icon={faTimes} className="text-2xl" />
            </button>
          </div>
        </div>
        
        {/* Scrollable content */}
        <div className="flex-1 overflow-y-auto p-6">
          {/* Image Container */}
          <div className="mb-6 relative">
            <div className="aspect-video w-full rounded-lg shadow-md overflow-hidden">
              <img 
                src={project.image} 
                alt={project.title} 
                className="w-full h-full object-contain bg-stone-900"
              />
            </div>
          </div>
          
          <div className="text-white space-y-4">
            <p>{project.description}</p>
          </div>
          
          {project.technologies && (
            <div className="mt-6">
              <h3 className="text-lg font-semibold text-white mb-2">Technologies Used:</h3>
              <div className="flex flex-wrap gap-2">
                {project.technologies.split(',').map((tech, index) => (
                  <span key={index} className="bg-yellow-600 text-white px-3 py-1 rounded-full text-sm">
                    {tech.trim()}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;