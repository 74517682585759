import React, { useState  } from 'react';
import Carousel from './Carousel';
import './Carousel.css';

const Portfolio = () => {
  const [activeSection, setActiveSection] = useState('projects');

  const slides = [
    {
      id: 1,
      title: 'Nerve signal generator',
      subtitle: 'Python, Flask, JavaScript, HTML, CSS, SQLite',
      description: 'I et projektsamarbejde med virksomheden Judex udviklede min projektgruppe og jeg en Nerve Signal Generator, et teknologisk apparat designet til at efterligne karakteristikaene af et nervesignal. Programmet blev udviklet på en Raspberry Pi, der opsætter et adgangspunkt, så man kan konfigurere og tilslutte netværk, før man fysisk kobler det til elektrodiagnostisk hardware for at lave manuelle tests på den tilknyttede elektrodiagnostiske software. Generatoren gav brugerne mulighed for at modulere intensiteten af nervesignalet, introducere tidsmæssige forsinkelser og inkorporere hvid støj, hvilket muliggjorde en nuanceret udforskning af signaldynamik. Brugergrænsefladen blev konstrueret ved hjælp af en kombination af Python til backend-funktionalitet, JavaScript til dynamisk interaktivitet, HTML/CSS til præsentation og en SQLite-database. De genererede nervesignaler blev visualiseret og manipuleret gennem et JavaScript-baseret diagram, hvilket faciliterede brugerinteraktion og tilpasning gennem grafisk manipulation af datapunkter inden for diagrammets grænseflade.',
      image: './images/nsg.png'
    },
    {
      id: 2,
      title: 'Fra Desktop app til Webapp',
      subtitle: 'C#, ASP.NET Core, Angular, MS SQL Server',
      description: 'I samarbejde med Treat Systems ApS og Aalborg Universitet (AAU) arbejdede min projektgruppe og jeg på at konvertere en eksisterende prototype fra en desktop-løsning til en webbaseret løsning. Dette beslutningsstøttende værktøj, kaldet GlucoSafe, er designet til at hjælpe med at styre ernæring og blodsukkerniveau hos patienter på intensivafdelinger. En avanceret algoritme, udviklet af en gruppe forskere, herunder Ulrike Sabine Pielmeier, lektor og PhD i sundhedsvidenskab og teknologi ved AAU, anvender patientdata, ernæring og insulinbehandlinger til at forudsige blodsukkerniveauet og give anbefalinger til optimal behandling. \n  Projektets hovedmål var at omdanne GlucoSafeDesktop til en webbaseret løsning. Dette involverede en omskrivning af algoritmen fra Java til C# samt implementering af et nyt webbaseret system med samme funktionalitet. Backend blev udviklet ved hjælp af ASP.NET Core til API’et, mens frontend blev udviklet i Angular for at skabe en dynamisk og interaktiv brugergrænseflade. Systemets data blev gemt i en Microsoft SQL Server, og den avancerede algoritme blev oversat.',
      image: './images/gs-udklip.png'
    },
    {
      id: 3,
      title: 'Hjemmeside Portefølje',
      subtitle: 'React, HTML, Tailwind CSS, JavaScript, PHP',
      description: 'Det er denne hjemmeside, som du ser lige nu. Jeg har udviklet denne hjemmeside som en del af min portefølje. Hjemmesiden er skrevet i React og bruger Tailwind CSS til styling. Jeg har også brugt JavaScript til at lave interaktive elementer og PHP til at sende formulardata fra kontaktformularen til min e-mail.',
      image: './images/hjemport.png',
    },
    {
      id: 4,
      title: 'Discord bot',
      subtitle: 'Node.js, Discord.js, JavaScript',
      description: 'Jeg har udviklet en Discord bot, der kan udføre forskellige kommandoer og funktioner i en Discord server. Boten er skrevet i Node.js og bruger Discord.js-biblioteket til at interagere med Discord API\'et.',
      image: './images/nutbot.png',
    },
    {
      id: 5,
      title: 'Suduko generator',
      subtitle: 'Java, HTML',
      description: 'Jeg har udviklet en Suduko generator, som kan generere et nyt Suduko-puslespil når man indlæser siden. Sudokuen vises i en HTML-side.',
      image: './images/sudoku.png',
    },
    {
      id: 6,
      title: 'System til lagerstyring',
      subtitle: 'React, HTML, Tailwind CSS, JavaScript, PHP',
      description: 'Jeg har lavet et lagerstyringssystem, som kan holde styr på lagerbeholdningen af varer i forskellige boder/stande/butikker. Systemet er skrædersyet til en virksomhed som har et ret alternativt workflow. Jeg har desuden lavet et interface hvor man kan se statestikker for individuelle eller valgte butikker. Systemet er skrevet i React og bruger PHP til at kommunikere med en MariaDB-database.',
      image: './images/lagersystem.png',
    },
    {
      id: 7,
      title: '"Weak perspective projection" 2,5D',
      subtitle: 'HTML, JavaScript',
      description: 'Jeg har lavet en 2,5D effekt i et HTML canvas element. Effekten er baseret på "weak perspective projection" og giver en illusion af dybde i et 2D billede. Man kan bevæger og dreje "billedet" med q,e og w,a,s,d for at se effekten. Jeg har også tilføjet en kasse som kan bevæges rundt i billedet vha. piltasterne.',
      image: './images/weak-perspective.png',
    },
  ];

  const books = [
    {
      id: 1,
      title: 'Clean Architecture',
      subtitle: '"A Craftsman\'s Guide to Software Structure and Design"',
      description: 'Jeg har læst denne bog, da jeg er interesseret i softwarearkitektur, -design og bogen supplerede et kursus på Softwareudvikler uddannelsen. Bogen er skrevet af "Uncle Bob", og handler om hvordan man kan strukturere og designe software på en måde, der gør det nemt at vedligeholde og udvide. Bogen introducerer begreber som "The Dependency Rule", "The Clean Architecture", og "The SOLID Principles", som alle er vigtige for at skabe robust og skalerbar software.',
      image: './images/clean-architecture.jpg'
    },
    {
      id: 2,
      title: 'Clean Code',
      subtitle: '"A Handbook of Agile Software Craftsmanship"',
      description: 'Denne bog er en klassiker inden for softwareudvikling og en af mine personlige favoritter. Bogen er skrevet af "Uncle Bob", og handler om hvordan man kan skrive ren og læsbar kode. Bogen introducerer en række principper og praksisser, som kan hjælpe dig med at skrive kode der er nem at forstå, vedligeholde og udvide.',
      image: './images/clean-code.jpg'
    },
    {
      id: 4,
      title: 'Design Patterns',
      subtitle: '"Elements of Reusable Object-Oriented Software"',
      description: 'Denne bog er læst igennem mit uddannelses forløb og er en klassiker inden for softwareudvikling. Bogen introducerer 23 designmønstre til almindelige problemerstillinger i softwareudvikling. Designmønstrene er opdelt i tre kategorier: Creational, Structural og Behavioral. Bogen er en vigtig ressource for enhver softwareudvikler, der ønsker at skrive bedre og mere genanvendelig kode.',
      image: './images/design-patterns.jpg'
    },
    {
      id: 5,
      title: 'Fundamentals of Database Systems',
      subtitle: 'Database: fra idé til implementering - mm.',
      description: 'I gennem kurset "Database for udviklere" på Softwareudvikler uddannelsen har jeg læst denne bog. Bogen er en omfattende introduktion til forskellige typer af databaser og database design. Den dækker alt fra grundlæggende koncepter som relationelle databaser og SQL til mere avancerede emner som database design og implementering af databaseapplikationer. Bogen vender desuden emner som relationel algebra, normalisering, transaktioner, concurrency control, optimering og sikkerhed.',
      image: './images/database-systems.jpg'
    },
    {
      id: 6,
      title: 'The art of scalability',
      subtitle: 'Skalerbarhed i software, teknologi og organisation',
      description: 'I forbindelse med et kursus på min udannelse har jeg læst denne bog. Bogen vender emner som skalerbarhed i software, teknologi og organisation, og giver praktiske råd til at designe og implementere skalerbare systemer i praksis.',
      image: './images/taos.jpg'
    },
    {
      id: 7,
      title: 'Enterprise Integration Patterns',
      subtitle: '"Designing, Building, and Deploying Messaging Solutions"',
      description: 'I kurset "Systemintegration" på Softwareudvikler uddannelsen har jeg læst denne bog. Bogen er en omfattende guide til at designe, bygge og implementere integrationsløsninger ved hjælp af messaging. Den dækker alt fra koncepter som messaging patterns og message channels til emner som transaktioner, sikkerhed og skalerbarhed.',
      image: './images/enterprise-int.webp'

    },
    {
      id: 10,
      title: 'Applying UML and Patterns',
      subtitle: '"An Introduction to Object-Oriented Analysis and Design and Iterative Development"',
      description: 'Bogen er en introduktion til objektorienteret analyse og design og iterativ udvikling. Den dækker alt fra grundlæggende koncepter som UML og objektorienteret analyse til mere avancerede emner som design patterns og iterative udviklingsmetoder.',
      image: './images/applying-uml.jpg'
    },
  ]

  const hobbyBooks = [
    {
      id: 1,
      title: '7 Habits of Highly Effective People',
      subtitle: 'Stærke vaner for personlig og professionel succes',
      description: 'Dette er klart en af mine favoritbøger, da den har hjulpet mig med at forstå konceptet omkring at være en proaktiv person og tage kontrol i dit liv. Bogen er skrevet af Stephen Covey. I bogen beskriver Covey hvordan vi kan udvikle vores personlige og professionelle liv ved at fokusere på vores vaner og værdier. Covey argumenterer for, at vi bør fokusere på at udvikle vores karakter og integritet, så vi kan opnå personlig og professionel succes. Jeg synes det er en vigtig bog fordi den minder os om, at vi har ansvaret for vores eget liv og dermed også muligheden for at tage kontrollen for vores liv. Yndlings fra bogen citat: "Between stimulus and response, there is a space. In that space lies our freedom and power to choose our response. In our response lies our growth and our happiness."',
      image: './images/7-habits.jpg'
    },
    {
      id: 2, 
      title: 'Atomic Habits',
      subtitle: '"An Easy & Proven Way to Build Good Habits & Break Bad Ones"',
      description: 'Uanset dine mål tilbyder "Atomic Habits" en gennemprøvet ramme for daglige forbedringer. Bogen beskriver praktiske strategier som lærer en præcis, hvordan man former gode vaner, bryder dårlige vaner samt hvordan man mestrer de små adfærdsændringer, der fører til bemærkelsesværdige resultater.',
      image: './images/atomic-habits.jpg'
    },
    {
      id: 3,
      title: 'Essentialism',
      subtitle: '"Less is more"',
      description: 'Denne bog er interessant, da den handler om at fokusere på det væsentlige og skære alt det overflødige fra. Forfatteren, Greg McKeown, argumenterer for at vi ofte bruger for meget tid og energi på ting, der ikke er vigtige for os, og at vi derfor bør fokusere mere på det, der virkelig betyder noget for os. Han giver en række konkrete råd til, hvordan vi kan blive bedre til at sige nej til ting, der ikke er vigtige for os, og hvordan vi kan fokusere mere på det, der virkelig betyder noget for os. Jeg synes det er en vigtig bog, fordi den minder os om, at vi ikke kan gøre alt og at vi derfor bør vælge med omhu, hvad vi bruger vores tid og energi på.',
      image: './images/essentialism.jpg'
    },
    {
      id: 4,
      title: 'Thinking, Fast and Slow',
      subtitle: 'Den hurtige intuative, og den langsomme rationelle tænkning',
      description: 'Jeg synes denne bog er vildt interessant, da den giver et indblik i, hvordan vi tænker og træffer beslutninger. Bogen er skrevet af nobelprisvinderen Daniel Kahneman, som er en af verdens førende eksperter i adfærdsøkonomi og psykologi. Han beskriver i bogen, hvordan vores hjerne er delt op i to systemer, som han kalder system 1 og system 2. System 1 er hurtigt og intuitivt, mens system 2 er langsomt og rationelt. Kahneman viser, hvordan disse to systemer arbejder sammen og nogle gange imod hinanden, og hvordan vi kan bruge denne viden til at træffe bedre beslutninger.',
      image: './images/fast-slow.jpg'
    },
    {
      id: 5,
      title: 'Lord of the Rings Trilogy',
      subtitle: 'The Fellowship of the Ring, The Two Towers, The Return of the King',
      description: 'Jeg holder meget af at fordybe mig i et fantasy eller sci-fi univers i min fritid. Lord of the Rings er nogle af mine favoritbøger.',
      image: './images/lotr.jpg'
    }
  ]

  return (
    <section id="portfolio" className="relative bg-stone-900 pt-16 py-24">
      <div className="container mx-auto py-24 px-4">
        <div className="flex justify-center mb-8 mx-auto px-4">
          <nav className="inline-flex rounded-md shadow-sm" role="group">
            <button
              type="button"
              className={`px-4 py-2 text-sm font-medium rounded-l-lg md:w-32 w-28 ${
                activeSection === 'projects'
                  ? 'bg-yellow-600 text-white'
                  : 'bg-stone-800 text-gray-300 hover:bg-stone-700'
              }`}
              onClick={() => setActiveSection('projects')}
            >
              Projekter
            </button>
            <button
              type="button"
              className={`px-4 py-2 text-sm font-medium md:w-32 w-24 ${
                activeSection === 'books'
                  ? 'bg-yellow-600 text-white'
                  : 'bg-stone-800 text-gray-300 hover:bg-stone-700'
              }`}
              onClick={() => setActiveSection('books')}
            >
              Fagbøger
            </button>
            <button
              type="button"
              className={`px-4 py-2 text-sm font-medium rounded-r-lg md:w-32 w-28 ${
                activeSection === 'hobbyBooks'
                  ? 'bg-yellow-600 text-white'
                  : 'bg-stone-800 text-gray-300 hover:bg-stone-700'
              }`}
              onClick={() => setActiveSection('hobbyBooks')}
            >
              Hobbybøger
            </button>
          </nav>
        </div>
        <div>
          <div className={activeSection === 'projects' ? 'block' : 'hidden'}>
            <Carousel slides={slides} isPageBackground type="project" />
          </div>
          <div className={activeSection === 'books' ? 'block' : 'hidden'}>
            <Carousel slides={books} isPageBackground type="book" />
          </div>
          <div className={activeSection === 'hobbyBooks' ? 'block' : 'hidden'}>
            <Carousel slides={hobbyBooks} isPageBackground type="book" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
